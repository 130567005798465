var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-view',{ref:"modal",attrs:{"mainSetting":_vm.modalSetting},on:{"on-cancel":_vm.cancel,"on-ok":_vm.save},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{attrs:{"slot":"modal-content-side1"},slot:"modal-content-side1"},[(_vm.modeldetail != undefined)?_c('v-list',{attrs:{"two-line":"","color":"grey lighten-4 py-0"}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"35"}},[_c('v-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.modeldetail.code))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.modeldetail.product_name))]),_c('v-list-item-subtitle',[_c('span',{staticClass:"grey--text"},[_vm._v("Stock")]),_vm._v(": "),_c('b',[_vm._v(_vm._s(_vm.modeldetail.stock))]),_c('span',{staticClass:"grey--text ml-6"},[_vm._v("Costo Anterior")]),_vm._v(": "),_c('b',[_vm._v(_vm._s(_vm.modeldetail.product_cost))])])],1)],1)],1):_vm._e(),_c('v-divider'),_c('form-base',{ref:"form",attrs:{"model":_vm.modeldetail,"items":_vm.items},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[(_vm.allowEditPrices)?_c('div',{staticStyle:{"width":"100%"},attrs:{"slot":"after_fields"},slot:"after_fields"},[_c('v-widget',{attrs:{"title":"Precios","simpleToolbar":true,"icon":"payments","toolbarColor":"secondary","contentBg":"px-0"}},[_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.columns,"items":_vm.prices,"disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"detail-quantity",staticStyle:{"max-width":"120px"},attrs:{"suffix":"%","type":"number","dense":"","hide-details":""},on:{"input":function($event){return _vm.updateItemProfit(item)}},model:{value:(item.profit),callback:function ($$v) {_vm.$set(item, "profit", $$v)},expression:"item.profit"}})]}},{key:"item.price_rule_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPriceName(item.price_rule_id))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"type":"number","prefix":_vm.currencySign(item.currency_id),"dense":"","hide-details":""},on:{"input":function($event){return _vm.updateItemPrice(item)},"change":function($event){return _vm.changeItemPrice(item)}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}},{key:"item.product_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_price.toFixed(2))+" ")]}}],null,false,2032534876)})],1)])],1):_vm._e()])],1),_c('template',{slot:"modal-actions"},[_c('v-badge',{attrs:{"content":_vm.discount,"value":_vm.discount,"color":"red","overlap":""}},[_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(_vm._s(_vm.total))])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }